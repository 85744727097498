.imagesInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 10px !important;
}

.imagesInfo img {
  width: 30%;
}

.custom-popover {
  max-height: 300px; 
  overflow-y: auto;
 
}

.terms-conditions {
text-align: center;
font-weight: bold;
margin-bottom: 4px;
}

.conditions {
  font-size: 16px;
  margin-bottom: 4px;
}

.text-grey {
  color:#808080;
}

.div-button-popover {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
}

.div-button-popover button{
  border: none;
  font-size: 25px;
  width: 48px;
  height: 48px;
}
