.divIconCheckGreen {
  width: 15px;
  height: 15px;
}

.iconCheckGreen {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.checkGreen {
  box-sizing: border-box;
  background-color: #33d713;
  position: relative;
  display: block;
  transform: scale(75%, 75%) !important;
  width: 22px;
  height: 22px;
  border: 2px solid #33d713;
  border-radius: 100px;
}

.checkGreen::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 3px 3px 0;
  border-color: white !important;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}
