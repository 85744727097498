.genericWhatsapp {
  width: 24px;
  height: 24px;
}
.iconWhatsapp {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.divWhatsapp {
  background: var(--bg_button);
  width: 18em;
  height: 18em;
  border-radius: 50%;
  position: relative;
  border: 25px solid var(--color_text_button);
  transform: rotate(10deg) scale(10%, 10%) translate(-485%, -379%);
}

.triangleWhatsapp {
  position: absolute;
  bottom: -1.4em;
  left: 0.2em;
  width: 4em;
  height: 4em;
  transform: rotate(-20deg) skew(-20deg);
  background: var(--bg_button);
  border-left: 25px solid var(--color_text_button);
  border-bottom: 25px solid var(--color_text_button);
  z-index: 5;
}

.phoneWhatsapp {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7em;
  height: 10.3em;
  border: 25px solid var(--color_text_button);
  transform: translate(-50%, -50%) rotate(-60deg);
  border-radius: 50%;
}

.phoneWhatsapp div {
  position: absolute;
  top: 50%;
  right: -4em;
  width: 6.3em;
  height: 13em;
  background: var(--bg_button);
  transform: translateY(-50%);
  border-radius: 0 4em 4em 0;
  z-index: 1;
}

.phoneWhatsapp:before,
.phoneWhatsapp::after {
  content: "";
  position: absolute;
  left: 0.6em;
  width: 2.5em;
  height: 3.5em;
  background: var(--color_text_button);
  z-index: 3;
}

.phoneWhatsapp:before {
  top: 5.3em;
  left: 0.4em;
  border-radius: 5px 5px 10px 50%;
  transform: rotate(-15deg);
}

.phoneWhatsapp::after {
  bottom: 5.3em;
  left: 0.4em;
  border-radius: 50% 10px 5px 5px;
  transform: rotate(15deg);
}
