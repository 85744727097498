.divIconArrowRight {
  width:40px;
  height: 30px;
}

.iconArrow {
  position: relative;
  top: -46%;
  left: -54%;
  transform: translate(-50%,-50%);
  transform: scale(25%,25%) !important;
  width: 80px;
  height: 60px;
  cursor: pointer;
}

.arrow-right {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: var(--color_text);
  border-radius: 40px;
}

.arrow-right::after, .arrow-right::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color: var(--color_text);
  border-radius: 40px
}

.arrow-right::after {
  top: -12px;
  transform: rotate(45deg);
  border-radius: 40px
}

.arrow-right::before {
  top: 12px;
  transform: rotate(-45deg);
  border-radius: 40px
}