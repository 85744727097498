.chat-form-chat .row-error404 {
    height: 100vh;
    background-color: black !important;
}

.chat-form-chat .col-text-404 {
    display: flex;
    justify-content: center;
}

.chat-form-chat .div-text-404 {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 12px;
}

.chat-form-chat .div-text-404 h2 {
    font-size: 64px;
    font-weight: 800;
    margin-bottom: 32px;
 }

 .chat-form-chat .div-text-404 .p-404 {
    font-size: 20px;
    font-weight: 800;
}

.chat-form-chat .div-text-404 .p-text {
    margin-bottom: 32px;
}