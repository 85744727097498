@font-face {
  font-family: "Urbanist";
  src: url(../../assets/fonts/Urbanist/Urbanist-Regular.ttf);
}

@font-face {
  font-family: "Inter";
  src: url(../../assets/fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: "Cinzel-Bold";
  src: url(../../assets/fonts/Cinzel/Cinzel-Bold.otf);
}
@font-face {
  font-family: "Cinzel-Regular";
  src: url(../../assets/fonts/Cinzel/Cinzel-Regular.otf);
}

@font-face {
  font-family: "Efco";
  src: url(../../assets/fonts/Efco/Efco.otf);
}

@font-face {
  font-family: "Copperplate";
  src: url(../../assets/fonts/CopperPlate/OPTICopperplate.otf);
}
@font-face {
  font-family: "Didot";
  src: url(../../assets/fonts/Didot/Didot-Regular.ttf);
}
@font-face {
  font-family: "Chloe";
  src: url(../../assets/fonts/Chloe/Chloe-Regular.otf);
}
@font-face {
  font-family: "TrueLove";
  src: url(../../assets/fonts/TrueLove/TrueLove-bold.ttf);
}
@font-face {
  font-family: "AndersonGrotesk";
  src: url(../../assets/fonts/AndersonGrotesk/AndersonGrotesk-Light.otf);
}
@font-face {
  font-family: "Lexend";
  src: url(../../assets/fonts/Lexend/Lexend-Light.ttf);
}
/*VARIABLES DE LAS CLASES BASICAS PARA VIP !== 6 */
:root {
  --bg_color_header: #00a884;
  --bg_color_container: #ffffff;
  --bg_color_assistant: #f9f9f6;
  --bg_color_page_loading: black;
  --bg_button: #00a884;
  --bg_color: #f8f8f8;
  --bg_image: "";
  --bg_input: #d9fdd1;
  --color_border: none;
  --color_border_assistant: #3330;
  --color_dot_dark: #e8e8e8;
  --color_dot_light: #cccccc;
  --color_filter: rgba(0, 0, 0, 0);
  --color_input_span: grey;
  --color_text: #000000;
  --color_background_carrousel: #d9fdd1;
  --color_text_carrousel: #000000;
  --color_text_button: #ffffff;
  --color_text_image: #ffffff;
  --color_text_header: #ffffff;
  --typography: "Inter";
  --typography_description: "Urbanist";
}
/* ------------ FIN --------------- */

/*CLASES QUE CONTIENEN VARIABLES */
.backgroundContainer {
  position: relative;
  overflow: hidden;
}

.containerTemplateBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg_color);
  background-image: var(--bg_image);
  z-index: -1;
}
.divPageLoading {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.divPageLoadingTiny {
  background-color: #ff6aa3;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logotypePageLoading {
  width: 208px;
}

.backgroundColorAssistant {
  background-color: var(--bg_color_assistant);
}

.containerTemplate {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.fontVar {
  font-family: var(--typography) !important;
}
.backgroundCarrousel {
  background-color: var(--color_background_carrousel);
}
.textCarrousel {
  color: var(--color_text_carrousel);
}
.textDivColor {
  color: var(--color_text_image);
}
.fontVarDescription {
  font-family: var(--typography_description);
}
.borderColorVar {
  border: 1px solid var(--color_border_assistant);
  border-bottom: none;
  border-radius: 16px 16px 0px 0px;
}
.borderColorLabelVar {
  border: 1px solid var(--color_border) !important;
}
.colorTextVar {
  color: var(--color_text) !important;
}
.colorTextVarCarousel {
  color: var(--color_text_carrousel) !important;
}
.backgroundColorHeader {
  background-color: var(--bg_color_header);
}
.colorTextHeader {
  color: var(--color_text_header);
}
#colorTextHeader {
  color: var(--color_text_header);
}
.colorTextButton {
  color: var(--color_text_button) !important;
}
.colorTextImage {
  color: var(--color_text_image) !important;
}
.colorSpantVar {
  color: var(--color_input_span);
}
.colorDotDark {
  color: var(--color_dot_dark);
}
.colorDotVar .message.loading::before {
  background-color: var(--color_text) !important;
}
.colorDotVar .message.loading span::before {
  background-color: var(--color_text) !important;
}
.colorDotVar .message.loading span::after {
  background-color: var(--color_text) !important;
}
.backgroundInputVar {
  background-color: var(--bg_input) !important;
  border-radius: 16px;
}
.backgroundColorContainer {
  background-color: var(--bg_color_container);
}

.containerTemplate .dotDark {
  background: var(--color_dot_dark);
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50px;
}
.dotLight {
  background: var(--color_dot_light);
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50px;
}
.containerTemplate .divButtonFormTemplate .botonFormTemplate2 {
  width: 282px;
  height: 44px;
  border-radius: 10px;
  background-color: var(--bg_button);
  color: var(--color_text_button);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerTemplate .iconW2 {
  margin-left: 5% !important;
  filter: opacity(0.5) drop-shadow(0 0 var(--color_filter));
}
#colorNone {
  filter: opacity(0.5) drop-shadow(0 0 var(--color_text));
}
.colorNone {
  filter: opacity(0.5) drop-shadow(0 0 var(--color_text));
}

.containerTemplate .divInputFormChat {
  width: 220px;
  background: var(--bg_input) !important;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
}
.containerTemplate .divInputFormChatEmail {
  width: 220px;
  background: var(--bg_input) !important;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
}
.containerTemplate .inputGenericFormChat,
.containerTemplate .inputNameFormChat {
  font-family: "Urbanist" !important;
  background: transparent !important;
  color: var(--color_text) !important;
  width: 220px;
  padding: 16px 8px;
  border: none;
  box-shadow: none !important;
}

.containerTemplate .inputDisabled {
  pointer-events: none !important;
}

.containerTemplate .divInputTelFormChat input {
  width: 100%;
  background-color: transparent;
  color: var(--color_text);
  border: none;
  box-shadow: none !important;
}
.containerTemplate .divGenericInputTattooFormChat {
  background-color: var(--bg_input);
  border-radius: 10px;
  z-index: 1;
}
.containerTemplate .divGenericInputTattooFormChat .borderNoneFormChatSe {
  border-bottom: 1px solid var(--color_dot_light);
}
.containerTemplate .divRadioBisFormChat p {
  color: var(--color_text);
  margin: 0;
  padding-left: 8px;
}
.containerTemplate .file-upload-coverpage {
  background-color: var(--bg_input);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.containerTemplate .inputTextarea2 {
  font-family: "Urbanist" !important;
  background-color: var(--bg_input);
  color: var(--color_text);
  width: 100%;
  padding: 12px 8px;
  border: none;
  box-shadow: none !important;
  overflow-y: scroll !important;
}
.containerTemplate .inputTextarea2:focus {
  background-color: var(--bg_input);
  color: var(--color_text);
}

.containerTemplate .divDescriptionFormChat .inputTextarea2:disabled {
  background-color: var(--bg_input);
}

.containerTemplate .inputTextareaAnotherQuery {
  max-width: 478px !important;
}

.buttonSendImageImagesTatto2 {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 220px;
  background-color: var(--bg_input) !important;
  color: var(--color_text) !important;
  margin: 24px 16px 24px 0px;
  border: none !important;
  box-shadow: none !important;
  z-index: 1;
}
.buttonSendImageImagesTatto2:focus {
  display: flex;
  background-color: var(--bg_input) !important;
  color: var(--color_text) !important;
  margin: 24px 16px 24px 0px;
  border: none !important;
  box-shadow: none !important;
}

.containerTemplate .buttonSendImageImagesTatto img,
.containerTemplate .buttonSendImageImagesTatto2 img {
  width: 20px;
  height: 20px;
  margin: 3px 0px 0px 4px;
  filter: var(--color_filter);
  margin-top: 0 !important;
}
.containerTemplate .sizeInputTermsFormChat2 {
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 8px;
  background-color: transparent;
  border: 1px solid var(--color_text);
  border-radius: 2px;
}
.containerTemplate .sizeInputTermsFormChatCarousel {
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 8px;
  background-color: transparent;
  border: 1px solid var(--color_text_carrousel);
  border-radius: 2px;
}
.containerTemplate .sizeInputTermsFormChat2:checked {
  border: 1px solid var(--color_text);
}
.containerTemplate .spamTerminosFormChat2 {
  font-size: 14px;
  color: var(--color_text) !important;
  font-weight: bold;
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  letter-spacing: 0.06em !important;
  box-shadow: none !important;
  outline: none !important;
}
.containerTemplate .spamTerminosFormChatCarousel {
  font-size: 14px;
  color: var(--color_text_carrousel) !important;
  font-weight: bold;
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  letter-spacing: 0.06em !important;
  box-shadow: none !important;
  outline: none !important;
}

.containerTemplate .divButtonFormTemplate .botonFormTemplate {
  width: 282px;
  height: 44px;
  border-radius: 10px;
  background-color: var(--bg_button);
  color: var(--color_text_button) !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerTemplate .pTerminosFormChat2 {
  color: var(--color_text) !important;
  width: 90%;
}
.containerTemplate .pTerminosFormChatCarousel {
  color: var(--color_text_carrousel) !important;
  width: 90%;
}
.divGradient {
  background: linear-gradient(#e66465, #9198e5);
}
/* ------------ FIN --------------- */

.urbanist {
  font-family: "Urbanist" !important;
  letter-spacing: 0.06em !important;
}

body {
  box-sizing: border-box;
}

.backGroundColorFormTemplate {
  background-color: #232323;
}
.backGroundColorFormTemplate2 {
  background-color: #909090;
  border: none !important;
}

.color-text-black {
  color: black;
  text-align: center;
}

.containerTemplate .textBoldFormChat {
  font-weight: bold !important;
  font-weight: -webkit-bold !important;
  margin-bottom: 0;
}

.containerTemplate .textBreak {
  width: 95%;
  overflow-wrap: break-word;
  padding: 0 8px;
}

.containerTemplate .pFC,
.containerTemplate .divUploadImagesBodyPartFormChat {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
}

.containerTemplate .pFC,
.containerTemplate .divUploadImagesBodyPartFormChat {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
}

.containerTemplate .div-text-error {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
  color: red;
}

.containerTemplate .pFCSE {
  justify-content: center !important;
}

.containerTemplate .divUploadImagesBodyPartFormChat {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  max-width: 512px !important;
}
.containerTemplate .iconW {
  height: 25px;
  width: 25px;
  margin-left: 8px;
  object-fit: unset !important;
  filter: invert();
}

.containerTemplate .chat {
  border: none;
  width: 100%;
  height: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
}
#color2e2e2e {
  color: #2e2e2e !important;
}

#backgrounNonedSe {
  background-color: unset !important;
}
#backgroundSe p {
  color: #2e2e2e !important;
}
#filterInvert {
  filter: invert(1) !important;
}
#filterInvert2 {
  filter: invert(0) !important;
}

.containerTemplate .divUnique01ProgressSeven {
  background: #2e2e2e;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50px;
}

.containerTemplate .divUniqueProgressSeven {
  background: #909090;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50px;
}
#backgroundInputSeven {
  background: #909090 !important;
  border: #909090 !important;
}
.containerTemplate .chat::-webkit-scrollbar {
  display: none;
}

/* ///////////////COLOR SCROLL//////////// */
.containerTemplate .pFC ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.containerTemplate .pFC ::-webkit-scrollbar-thumb {
  background-color: #888;
}
/* ////////////////////////////////////////// */

.containerTemplate .chat-header {
  min-width: 0px;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px 16px 16px;
  top: 0px;
  z-index: 4;
  width: 100%;
  margin: 0px auto;
  position: fixed;
}
/* .containerTemplate .chat-form {
  margin-top: 140px;
} */
.txt-center {
  text-align: center;
}

.containerTemplate .width-text {
  width: 120px;
  word-wrap: break-word;
}
.containerTemplate .width-text {
  width: 120px;
  word-wrap: break-word;
}
.containerTemplate .arrow-down {
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
}
.containerTemplate .divHeaderFormChat {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divHeaderFormChatNone{
  justify-content: unset !important;

}
.containerTemplate .divHeaderFormChat .container-logo-title {
  display: flex;
  align-items: center;
}

.containerTemplate .divHeaderFormChat Button,
.containerTemplate .divHeaderFormChat .show>.btn-success.dropdown-toggle {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding-top: 0;
}

.containerTemplate .divHeaderFormChat .dropdown-toggle::after {
  color: var(--color_text_header);
}

.containerTemplate .divHeaderFormChat .images-language {
  width: 25px !important;
}

.containerTemplate .divHeaderFormChat .dropdown-menu.show {
  display: flex !important;
  flex-direction: column !important;
  min-width: 35px !important;
  transform: translate3d(9px, 40px, 0px) !important;
  background-color: var(--bg_color_header);
}

.containerTemplate .avatar {
  width: 44px;
  height: 44px;
}

.containerTemplate .avatar .imageAvatarLogoFormTemplate {
  border-radius: 50% !important;
}

.containerTemplate .textlogoInitialFormTemplate {
  font-family: "Inter";
  font-size: 32px;
  width: 61px;
  height: 61px;
  margin: 0;
  background-color: white;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.containerTemplate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.containerTemplate .divTitle {
  width: 100% !important;
}

.containerTemplate .divDescription {
  margin-top: 8px;
}

.containerTemplate .title-hide {
  display: none;
}

.containerTemplate .divTitle p,
.containerTemplate .divDescription p {
  font-weight: normal;
  font-size: 32px;
  margin: 0;
  margin-left: 24px;
  overflow-wrap: break-word;
}

.containerTemplate .divTitle h1 {
  max-width: 74vw;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  margin-left: 24px;
  overflow-wrap: break-word;
}
.containerTemplate .divTitle .text-in-line {
  font-size: 14px;
  margin-left: 10px;
  font-family: "Urbanist";
}
.containerTemplate .divDescription p {
  max-width: 100vw !important;
  font-size: 14px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
}

.containerTemplate .messages,
.containerTemplate .messages2 {
  max-width: 575px;
  border: 1px solid #232323;
  border-radius: 10px 10px 0px 0px;
  overflow-y: inherit;
  z-index: 9999;
  border-bottom: none;
}

.containerTemplate .border-color {
  border-bottom: none !important;
}
.pdn50 {
  padding-bottom: 50%;
}
.pdn20 {
  padding-bottom: 20%;
}
.pdn5 {
  padding-bottom: 5%;
}

.containerTemplate .messages::-webkit-scrollbar,
.containerTemplate .messages2::-webkit-scrollbar {
  display: none;
}

.containerTemplate .divGenericLabelFormChat {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  margin-top: 18px;
  margin-bottom: 24px;
  overflow-wrap: break-word;
  z-index: 1;
}

.containerTemplate .divGenericLabelFormChatWelcome {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  margin-top: 18px;

  margin-bottom: 24px;
  overflow-wrap: break-word;
  z-index: 1;
}
.con-margen-superior {
  margin-top: var(--margen-superior);
}
.containerTemplate .divGenericLabelFormChatSaludo {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  margin-bottom: 24px;
  overflow-wrap: break-word;
  z-index: 1;
}

.containerTemplate .marginBottom8px {
  margin-bottom: 8px !important;
}

.containerTemplate .divGenericLabelColumnFormChat {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 24px;
}

.containerTemplate .divMultiImageFormTemplate {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.containerTemplate .divButtonSendImageImagesTatto {
  display: flex;
  justify-content: flex-end;
}

.containerTemplate .buttonSendImageImagesTatto {
  display: flex;
  margin: 24px 16px 24px 0px;
  border: none;
  box-shadow: none !important;
}

.containerTemplate .divImgCardFormTemplate {
  width: 128px;
  height: 128px;
  display: inline-block !important;
  position: relative;
}

.containerTemplate .labelImgFormTemplate {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  display: block !important;
  position: relative;
  user-select: none;
}

.containerTemplate .imgFormTemplate {
  width: 100%;
  height: 128px !important;
  filter: opacity(0.6);
  transition-duration: 0.2s;
  object-fit: cover;
  transform-origin: 50% 50%;
}

.containerTemplate .divPButtonImg {
  width: 130px;
  height: 130px;
  border-radius: 10px;
}

/* /////////////////////////////////////// */

.containerTemplate .buttonCPImage {
  width: 128px !important;
  height: 124px;
  padding: 0;
  border: none;
  background-color: unset;
  font-size: 16px;
}

.containerTemplate .buttonCPImageSize {
  width: 124px !important;
  height: 124px;
  padding: 0;
  background-color: #232323;
  border-radius: 10px;
  border: none;
}
/* ////////////////////////////// */

.containerTemplate .buttonCPImage img,
.containerTemplate .buttonCPImageSize img {
  width: 18px !important;
  height: 18px;
  padding: 0;
}

.containerTemplate .buttonCPImage .notImageButton,
.containerTemplate .buttonCPImageSize .notImageButton {
  width: 18px !important;
  height: 18px;
  padding: 0;
  filter: none !important;
}

.containerTemplate .divImgCardFormTemplate .btn-portfolio {
  top: 0px !important;
  right: 0px !important;
  position: absolute;
  z-index: 1;
  bottom: auto;
  margin-right: 0;
}

.containerTemplate .divImgCardFormTemplate .btn-delete {
  width: 17px !important;
  height: 18px;
  background-color: white;
  border: none;
  padding: 3px;
  border-radius: 50%;
  margin: 5px 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.containerTemplate .divImgCardFormTemplate .btn-delete img {
  filter: invert(1);
}

.containerTemplate .text-upload-template {
  padding: 0px 12px;
  margin: 0;
}

.containerTemplate .file-upload-coverpage input {
  position: absolute;
  height: 128px;
  width: 128px;
  left: -2px;
  top: -1px;
  z-index: 2;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
}

.containerTemplate .no-data-container-template {
  width: 128px;
  height: 128px !important;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerTemplate .no-data-container-template-border {
  border: 2px solid #33d713;
  border-radius: 10px;
}

.containerTemplate .no-data-container-template .image-upload-template {
  width: 18px !important;
  height: 18px !important;
}

.containerTemplate .genericLabelFormChat,
.containerTemplate .LabelInfoTattooFormChat,
.containerTemplate .genericLabelFormChatBubble {
  width: 196px;
  margin: 0%;
  padding: 8px;
  border-radius: 10px;
}

.containerTemplate .genericLabelFormChatBubble {
  position: relative;
}

.containerTemplate .genericLabelFormChatBubble:after {
  content: "";
  position: absolute;
  top: 12px;
  left: -19px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent var(--bg_color_container) transparent transparent;
}

.containerTemplate .LabelInfoTattooFormChat {
  width: 476px !important;
  margin: 0;
  margin-right: 16px;
  overflow-wrap: break-word;
}

.containerTemplate .marginTopLabelGenericFormChat {
  margin-top: 0px !important;
}

.containerTemplate .marginleft16 {
  margin-left: 16px !important;
  margin-bottom: 8px !important;
}

.containerTemplate .marginNoneLabelFormChat {
  margin-top: 0px !important;
}

.containerTemplate .avatarGenericLabelFormChat,
.containerTemplate .divInitialName {
  width: 28px;
  height: 28px;
}

.containerTemplate .avatarGenericLabelFormChat img {
  border-radius: 50%;
}

.containerTemplate .divInitialName {
  background-color: white;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
}

.containerTemplate .generic01LabelFormChat {
  width: 196px;
  padding: 8px;
  margin-bottom: 0px;
  border-radius: 10px;
  overflow-wrap: break-word;
  position: relative;
}

.containerTemplate .notMargin {
  margin-bottom: 0px !important;
}

.containerTemplate .label2NameUserChat {
  margin-bottom: 0;
  margin-top: 16px;
  padding: 8px 8px;
}

.containerTemplate .divGenericInputFormChat {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

.containerTemplate .divGenericColumnInputFormChat {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 24px;
}

.containerTemplate .divGenericColumnInputFormChatPiercing {
  display: flex;
  overflow: hidden;
  gap: 8px;
}

.containerTemplate .div-parts-subParts {
  align-items: revert;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.containerTemplate .div-parts-subParts-reverse {
  align-items: revert;
  justify-content: flex-start;
}

.containerTemplate .div-subParts {
  margin-right: 16px;
  padding-top: 81px;
  z-index: 1;
}

.containerTemplate .divGenericInputTattooFormChat2 {
  background-color: #909090;
  border-radius: 10px;
  z-index: 1;
}
.containerTemplate .divGenericInputTattooFormChat .borderNoneFormChat {
  border-bottom: 1px solid white;
}
.containerTemplate .divGenericInputTattooFormChat2 .borderNoneFormChat {
  border-bottom: 1px solid #2e2e2e;
}

.borderUp {
  border-radius: 16px 16px 0 0;
}
.borderBottom {
  border-radius: 0 0 16px 16px;
}
.containerTemplate
  .divGenericInputTattooFormChat
  .borderNoneFormChat:last-child {
  border-bottom: none !important;
}
.containerTemplate
  .divGenericInputTattooFormChat2
  .borderNoneFormChat:last-child {
  border-bottom: none !important;
}
.containerTemplate
  .divGenericInputTattooFormChat
  .borderNoneFormChatSe
  div:first-of-type {
  border-bottom: none !important;
}
.jo0 {
  border-bottom: none !important;
  border-radius: 20px;
}
.jo0:last-of-type {
  border-bottom: none !important;
  border-radius: 20px;
}

.responsiveContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.customCitySelector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.customCountryFormChat {
  margin-left: 10px; /* Añadir un pequeño margen a la derecha para separar */
  order: 2;
  align-self: flex-start;
}

.customCityFormChat {
  margin-right: 10px; /* Un pequeño margen a la izquierda para la separación */
  order: 1;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .customCitySelector {
    flex-direction: column; /* Cambiar a columnas en móviles */
  }

  .customCountryFormChat {
    order: 1; /* Mostrar arriba en móviles */
    margin: 0;
  }

  .customCityFormChat {
    order: 2; /* Mostrar debajo en móviles */
    margin: 0;
    margin-top: 10px;
  }
}
.divGenericInputTattooFormChatnone {
  background-color: transparent !important;
}
.containerTemplate
  .divGenericInputTattooFormChat
  .borderNoneFormChatSe:last-child {
  border-bottom: none !important;
  border-radius: 10px;
}

.containerTemplate .div-zindex {
  z-index: 0 !important;
}

.containerTemplate .divInputFormChat3 {
  width: 220px;
  background: #909090 !important;
  color: #2e2e2e !important;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.containerTemplate .divInputFormChat2 {
  width: 220px;
  background: transparent !important;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.containerTemplate .inputGenericFormChat2 {
  background: #909090 !important;
  color: #2e2e2e !important;
  width: 220px;
  padding: 16px 8px;
  border: none;
  box-shadow: none !important;
}
.containerTemplate .inputGenericFormChat2 {
  background: #909090 !important;
  color: #2e2e2e !important;
  width: 220px;
  padding: 16px 8px;
  border: none;
  box-shadow: none !important;
}
.containerTemplate .inputGenericFormChatSe {
  border-radius: 10px;
}
.logo5 {
  width: 54px !important;
  height: 54px !important;
}
.circle-logo {
  border: 1px solid #2e2e2e;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerTemplate .inputGenericFormChat3 {
  font-family: "Urbanist" !important;
  background: transparent !important;
  color: #2e2e2e !important;
  width: 220px;
  padding: 16px 8px;
  border: none;
  box-shadow: none !important;
}
.border7Up {
  border-radius: 7px 7px 0 0;
}
.containerTemplate .inputNameFormChat {
  width: 100%;
  border: none;
}
.containerTemplate .inputEmailFormChat {
  width: unset !important;
}
.containerTemplate .inputNameFormChatBorderNone {
  outline: none !important;
  height: 5px !important;
}

.containerTemplate .ButtonSendNameFormChat {
  background-color: transparent !important;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none !important;
}

.containerTemplate .ButtonSendNameFormChat img {
  width: 25px;
  height: 25px;
}

.containerTemplate .ButtonSendNameFormChat .imgColorSendNameFormChat {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(297deg)
    brightness(109%) contrast(101%);
}

.containerTemplate .inputTextarea {
  font-family: "Urbanist" !important;
  background: #232323 !important;
  color: white !important;
  width: 100%;
  padding: 12px 8px;
  border: none;
  box-shadow: none !important;
}

.containerTemplate .textTexarea {
  width: 100%;
}

.containerTemplate .sizeTextareaFormChat {
  resize: none;
  overflow: hidden;
  max-width: 220px;
  height: 176px;
  padding: 8px;
}

.containerTemplate .numbersTextareaFormChat {
  font-size: 12px;
  margin: 0;
}

.containerTemplate .divTextFormChat,
.containerTemplate .divTextFormChatRed,
.containerTemplate .divTextFormChatGreen,
.containerTemplate .divTextFormChatGreenBis {
  width: 220px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
}

.containerTemplate .divTextFormChatGreenBis {
  filter: none !important;
}

.containerTemplate .marginTextEmail {
  margin-top: 0;
  margin-left: 10px;
}

.containerTemplate .divTextFormChatRed {
  filter: invert(29%) sepia(98%) saturate(6875%) hue-rotate(356deg)
    brightness(87%) contrast(93%) !important;
}

.containerTemplate .divTextFormChatGreen {
  filter: invert(56%) sepia(84%) saturate(577%) hue-rotate(65deg)
    brightness(100%) contrast(97%) !important;
}

.containerTemplate .iconInformationName,
.containerTemplate .iconInformationNameNotRotate {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  filter: opacity(0.5) drop-shadow(0 0 var(--color_text));
}

.containerTemplate .iconInformationNameNotRotate {
  transform: rotate(180deg);
}

.containerTemplate .divTextFormChat p,
.containerTemplate .divTextFormChatRed p,
.containerTemplate .divTextFormChatGreen p {
  width: 220px;
  display: flex;
  justify-content: flex-start;
  margin-left: 12px;
  margin-bottom: 0px;
}

.rowFormChat {
  margin: 0 !important;
}

.colFormChat {
  padding: 0 !important;
}

.containerTemplate .divGenericBodyPartFormChat {
  display: flex;
  overflow: scroll;
  gap: 8px;
  font-size: 14px;
}

.containerTemplate .divDescriptionFormChat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 24px;
}

.containerTemplate .divGenericBodyPartFormChat::-webkit-scrollbar {
  display: none;
}

.containerTemplate .divScrollBodypart {
  max-width: 900px;
  display: flex;
  gap: 8px;
}
.margin20px {
  margin-bottom: 20px !important;
}

.containerTemplate .divBodyPartFormChat {
  width: 128px;
  height: 128px;
  border-radius: 11px;
  background-size: cover;
  background-position: center;
}

.styles-images-body-piercing {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.containerTemplate .divTextBodyPartPiercing p {
  text-align: center;
  width: 128px;
  font-weight: bold;
  margin-bottom: 8px;
  word-wrap: break-word;
}

.containerTemplate .divBodyPartFormChat .inputRadioFormChat.is-valid {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-top: 5px;
  border: none !important;
  border-color: unset !important;
}

.divBodyPartFormChatEmployed {
  background-position: center;
  background-size: cover;
}

.containerTemplate .form-check-input {
  background-size: unset !important;
}

.containerTemplate .inputRadioFormChat:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.containerTemplate .inputRadioFormChat:checked {
  box-shadow: none !important;
  border-color: none !important;
}

.containerTemplate .color_text_carrousel {
  color: var(--color_text_carrousel) !important;
}

.containerTemplate .divTextDefinitionLabelImage,
.divTextDefinitionImage {
  width: 100%;
  height: 97px;
}

.containerTemplate .divSelectProgress {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 8px;
  margin-top: 16px;
}

.containerTemplate .divUnique01Progress {
  background: #787878;
}

.containerTemplate .divRadioBisFormChat {
  display: flex;
}

.containerTemplate .divRadioBisFormChat .input-group-text {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 8px;
}

.containerTemplate .divRadioBisFormChat .inputRadioFormChat.is-valid {
  margin: 0;
  margin-top: 4px;
  padding-left: 10px;
  border: none !important;
  border-color: unset !important;
}

.containerTemplate .divInputTelFormChat {
  padding-top: 11px;
  padding-bottom: 10px;
}

.containerTemplate .divInputTelFormChat2 input {
  width: 100%;
  background-color: transparent;
  color: #2e2e2e !important;
  border: none;
  box-shadow: none !important;
  height: 56px;
}
.containerTemplate .divInputTelFormChat .flag-dropdown {
  background-color: transparent !important;
  color: white;
  border: none;
}
.containerTemplate .divInputTelFormChat .flag-dropdown .selected-flag {
  background-color: transparent !important;
  color: white;
  border: none;
}
.containerTemplate .divInputTelFormChat2 .flag-dropdown {
  background-color: transparent !important;
  color: white;
  border: none;
}

.containerTemplate .marginTextPhone {
  margin-bottom: 10px !important;
}

.containerTemplate .country-list {
  background-color: #232323;
  border-radius: 10px;
  width: 220px;
}

.containerTemplate .country-list::-webkit-scrollbar {
  display: none;
}

.containerTemplate .divGenericMessageFinalInfoTattoo {
  display: flex;
  justify-content: center;
}

.containerTemplate .divMessageFinalInfoTattoo {
  width: 268px;
  background-color: #232323;
  border-radius: 10px;
  padding: 8px;
  overflow-wrap: break-word;
}

.containerTemplate .divTermsFormChat {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.containerTemplate .pTerminosFormChat,
.containerTemplate .spamTerminosFormChat {
  color: white;
  font-size: 14px;
  text-decoration: unset !important;
  width: 90%;
}

.containerTemplate .paddingButtonCheck {
  padding: 0 16px;
}
.containerTemplate .spamTerminosFormChat {
  color: #5e5e5e !important;
  font-weight: bold;
  text-decoration: underline !important;
}

.containerTemplate .sizeInputTermsFormChat {
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 8px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 2px;
}

.containerTemplate .linkTermsFormChat {
  text-decoration: unset !important;
  text-align: center;
}

.containerTemplate .divButtonFormTemplate {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

.bounce {
  animation: 3s linear 0s infinite normal none running animationInkup;
}

@keyframes animationInkup {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0px) rotate(0deg);
  }
}

.containerTemplate .divButtonFormTemplate .botonFormTemplate {
  width: 282px;
  height: 44px;
  border-radius: 10px;
  background-color: var(--bg_button);
  color: var(--color_text_button) !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerTemplate .divButtonFormTemplate .botonFormTemplate img {
  margin-left: 5%;
}

.containerTemplate .buttonFormTemplateColor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 268px !important;
  height: 44px !important;
  background-color: #33d713 !important;
  border-radius: 10px;
  border: none;
}

.containerTemplate .divPTextErrorMessage {
  display: flex;
  justify-content: center;
}

.containerTemplate .buttonFormTemplateColorRed {
  background-color: #d71313 !important;
}

.containerTemplate .pTextErrorMessage {
  width: 268px;
  background-color: #d71313;
  color: white;
  margin: 8px 0px 50px 0px;
  padding: 8px;
  border-radius: 10px;
}

.containerTemplate .imgButtonFormTemplate {
  width: 18px !important;
  height: 18px !important;
}

.containerTemplate .inputRadioFormChat:checked[type="radio"] {
  background-image: url(../../assets/images/icon-Check-Green.svg) !important;
}

.containerTemplate .inputRadioFormChat:checked[type="checkbox"] {
  background-image: url(../../assets/images/icon-Checkbox-Square.svg) !important;
}

.containerTemplate .chat-scroll .scroll-body {
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 32px;
}

.containerTemplate .scroll-body::-webkit-scrollbar {
  display: none;
}

.containerTemplate .scroll-item {
  background: #fff;
  border: 1px solid #999;
  border-radius: 6px;
  color: #333;
  padding: 15px;
  margin-bottom: 10px;
}

.containerTemplate .divGenericFooterTemplate {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 512px;
  position: fixed;
  bottom: 0;
}

.containerTemplate .divFooterForChat {
  display: flex;
  justify-content: space-around;
  background-color: white;
  margin: 12px 0px;
  color: black;
  padding: 2px 2px;
  border-radius: 4px;
  cursor: pointer;
}

.containerTemplate a {
  line-height: initial;
}

.containerTemplate .divFooterForChat .button-footer {
  color: black;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  margin-right: 4px;
  box-shadow: none;
  letter-spacing: 0px;
  margin-top: 2px;
}

.containerTemplate .divFooterForChat img {
  width: 100%;
  height: unset !important;
  border-radius: unset;
}

.footer-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Esto elimina el subrayado del enlace */
}

@media screen and (max-width: 489px) {
  .containerTemplate .div-parts-subParts {
    flex-direction: column;
  }

  .containerTemplate .div-subParts {
    margin-top: 24px;
    padding-top: 0;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .containerTemplate .div-subParts-checks {
    width: 220px;
  }
}

@media screen and (any-pointer: fine) {
  .containerTemplate .divUnsetScrollFormChat::-webkit-scrollbar {
    display: unset;
    height: 8px;
    width: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .containerTemplate .chat-scroll .scroll-body {
    padding-bottom: 16px;
  }
  .containerTemplate .pdn50 {
    padding-bottom: 35% !important;
  }
}

@media screen and (min-width: 1500px) {
  .containerTemplate .chat-scroll .scroll-body {
    padding-bottom: 16px;
  }
}

@media screen and (max-height: 650px) {
  .containerTemplate .sizeTextareaFormChat {
    overflow: auto;
    height: 130px;
  }
}
/* esto hay que borrarlo cuando se quite el componente de prueba de initalButtons */
.bgButtonsContainer {
  background-color: #efefef;
}
.initalButtonsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.initalButtonsContainer button {
  width: 100% !important;
  margin-bottom: 15%;
  background-color: #d5fad0;
  color: #000000;
  border: none;
  border-radius: 10px;
  height: 50px;
}
.initalButtonsContainer button:hover {
  background-color: #d5fad0 !important;
  color: #000000 !important;
}
.initalButtonsContainer button:focus {
  background-color: #d5fad0 !important;
  color: #000000 !important;
}
.titleInitalButtonsContainer {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
}
.titleInitalButtonsContainer h4 {
  font-weight: bold;
}
.alertError{
  position: fixed !important;
    top: 15px !important;
    background-color: #ff3144eb !important;
    color: white !important;
}