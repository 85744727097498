 
 .hackeye {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  margin-bottom: 4px;
}
.arrowCta {
  display: block;
  height: 10px;
  width: 10px;
  border: 11px solid transparent;
  transform: rotate(315deg);
  position: relative;
 }

.arrowCta:after,
.arrowCta:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowCta:after {
  border-bottom: 3px solid var(--color_dot_dark);
  border-right: 3px solid var(--color_dot_dark);
  top: 0;
  left: 0;
  opacity: 1;
  animation: bottom-arrow 1.65s infinite;
}

@keyframes bottom-arrow {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
}

.arrowCta:before {
  top: 0;
  left: 0;
  border-bottom: 3px solid var(--color_dot_dark);
  border-right: 3px solid var(--color_dot_dark);
  animation: top-arrow 1.65s infinite;
}

@keyframes top-arrow {
  0% {
    transform: translate(-6px, -6px);
  }
  35% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}