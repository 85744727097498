.message.new {
    transform: scale(0);
    transform-origin: 0 0;
    -webkit-animation: bounce 500ms linear both;
    animation: bounce 1000ms linear both;
  }
  .message {
    clear: both;
    float: left;
    padding: 6px 10px 7px;
    border-radius: 10px 10px 10px 0;
    border: 1px solid #5e5e5e;
    margin: 8px 0;
    font-size: 11px;
    line-height: 1.4;
    /* margin-left: 35px; */
    position: relative;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  }
  .message.loading::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
    margin-top: 4px;
    -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate
      infinite;
    animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
    border: none;
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }

  .message.loading span {
    display: block;
    font-size: 0;
    width: 20px;
    height: 10px;
    position: relative;
  }
  .message.loading span::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
    margin-top: 4px;
    -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate
      infinite;
    animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
    margin-left: -7px;
  }

  .message.loading span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
    margin-top: 4px;
    -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate
      infinite;
    animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
    margin-left: 7px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes bounce {
    0% {
      transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    4.7% {
      transform: matrix3d(
        0.45,
        0,
        0,
        0,
        0,
        0.45,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    9.41% {
      transform: matrix3d(
        0.883,
        0,
        0,
        0,
        0,
        0.883,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    14.11% {
      transform: matrix3d(
        1.141,
        0,
        0,
        0,
        0,
        1.141,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    18.72% {
      transform: matrix3d(
        1.212,
        0,
        0,
        0,
        0,
        1.212,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    24.32% {
      transform: matrix3d(
        1.151,
        0,
        0,
        0,
        0,
        1.151,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    29.93% {
      transform: matrix3d(
        1.048,
        0,
        0,
        0,
        0,
        1.048,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    35.54% {
      transform: matrix3d(
        0.979,
        0,
        0,
        0,
        0,
        0.979,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    41.04% {
      transform: matrix3d(
        0.961,
        0,
        0,
        0,
        0,
        0.961,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    52.15% {
      transform: matrix3d(
        0.991,
        0,
        0,
        0,
        0,
        0.991,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    63.26% {
      transform: matrix3d(
        1.007,
        0,
        0,
        0,
        0,
        1.007,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    85.49% {
      transform: matrix3d(
        0.999,
        0,
        0,
        0,
        0,
        0.999,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    100% {
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }
  @keyframes bounce {
    0% {
      transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    4.7% {
      transform: matrix3d(
        0.45,
        0,
        0,
        0,
        0,
        0.45,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    9.41% {
      transform: matrix3d(
        0.883,
        0,
        0,
        0,
        0,
        0.883,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    14.11% {
      transform: matrix3d(
        1.141,
        0,
        0,
        0,
        0,
        1.141,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    18.72% {
      transform: matrix3d(
        1.212,
        0,
        0,
        0,
        0,
        1.212,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    24.32% {
      transform: matrix3d(
        1.151,
        0,
        0,
        0,
        0,
        1.151,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    29.93% {
      transform: matrix3d(
        1.048,
        0,
        0,
        0,
        0,
        1.048,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    35.54% {
      transform: matrix3d(
        0.979,
        0,
        0,
        0,
        0,
        0.979,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    41.04% {
      transform: matrix3d(
        0.961,
        0,
        0,
        0,
        0,
        0.961,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    52.15% {
      transform: matrix3d(
        0.991,
        0,
        0,
        0,
        0,
        0.991,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    63.26% {
      transform: matrix3d(
        1.007,
        0,
        0,
        0,
        0,
        1.007,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    85.49% {
      transform: matrix3d(
        0.999,
        0,
        0,
        0,
        0,
        0.999,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
    100% {
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }
  @-webkit-keyframes ball {
    from {
      transform: translateY(0) scaleY(0.8);
    }
    to {
      transform: translateY(-10px);
    }
  }
  @keyframes ball {
    from {
      transform: translateY(0) scaleY(0.8);
    }
    to {
      transform: translateY(-10px);
    }
  }