.divIconInfo {
  width: 15px;
  height: 15px;
}

.iconInfo {
  position: relative;
  top: 63%;
  left: 32%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.infoIcon {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(75%, 75%) !important;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color_input_span);
  border-radius: 40px;
}

.infoIcon::after,
.infoIcon::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: var(--color_input_span);
  transform: scale(75%, 75%) !important;
  left: 7px;
}

.infoIcon::after {
  bottom: 2px;
  height: 8px;
}

.infoIcon::before {
  height: 2px;
  top: 2px;
}
