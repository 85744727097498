.divIconDanger {
  width: 128px;
  height: 22px;
}

.iconDanger {
  position: relative;
  top: 10%;
  left: 42%;
  width: 22px;
  height: 22px;
  cursor: pointer;
  transform: scale(85%, 85%) !important;
}

.danger {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(180deg) !important;
  width: 22px;
  height: 22px;
  border: 2px solid var(--color_text);
  border-radius: 40px;
}
.danger::after,
.danger::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: var(--color_text);
  left: 7.5px;
}

.danger::after {
  bottom: 2px;
  height: 8px;
}

.danger::before {
  height: 2px;
  top: 2px;
}
