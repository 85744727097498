.divIconPlus {
    width: 128px;
    height: 22px;
   }
   
   .iconPlus {
    position: relative;
    /* top: 7%; */
    top: 61%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .plus {
     box-sizing: border-box;
     position: relative;
     display: block;
     transform: scale(90%,90%) !important;
     width: 22px;
     height: 22px;
     border: 2px solid var(--color_text);
     border-radius: 22px;
  }
  
  .plus::after, .plus::before {
     content: "";
     display: block;
     box-sizing: border-box;
     position: absolute;
     width: 10px;
     height: 2px;
     background: var(--color_text);
     border-radius: 5px;
     top: 8px;
     left: 4px;
  }
  
  .plus::after {
    width: 2px;
     height: 10px;
     top: 4px;
     left: 8px;
  }